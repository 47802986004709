.tag_input_wrap {
    border: 1px solid var(--grey4);
    padding: 4px 11px;
    border-radius: 10px;
    min-height: 2.75rem;
    input {
        color: var(--black4);
        flex: 1;
        &::placeholder {
            color: var(--grey8);
        }
    }

    input,
    input:focus,
    input:focus-visible,
    input:focus-within,
    input:hover {
        border: 0;
        outline: none;
    }
    :global {
        .ant-tag {
            margin-right: 0;
        }
    }
}
.existsText {
    color: var(--orange2);
}

.infoText {
    color: var(--grey7);
}
