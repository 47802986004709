.header {
  position: sticky;
  top: 0;
  background: var(--white);
  z-index: 10;
  .main_navbar {
    padding: 0.5em 1.5em;
    width: 100vw;
    overflow: auto;
  }
  .logo_icon {
    padding-right: 2.6em;
    border-right: 1px solid var(--grey5);
    img {
      width: 120px;
    }
  }
  .profile_wrap {
    cursor: pointer;
    img {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      border: 0 solid var(--grey6);
      padding: 0;
    }
    .roleName {
      font-size: 11px;
      font-weight: 600;
    }
  }
  .sub_navbar {
    padding: 1.2em 3.5em;
    border-top: 1px solid var(--grey1);
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    overflow-x: auto;
    .navbar_item {
      font-size: 16px;
      text-decoration: none;
      margin-right: 2.54rem;
      color: var(--black1);
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: var(--black2);
        transform: scale(1.04);
      }
    }
  }
}
