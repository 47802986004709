:root {
  // Black Colors
  --black: #000000;
  --black1: #000000a6;
  --black2: #000000cc;
  --black3: #00000013;
  --black4: #212529;
  --black5: #0000002d;

  // Blue Colors
  --blue1: #86b7fe;
  --blue2: #0d6efd40;
  --blue3: #0d6efd2e;
  --blue4: #0dcaf0;
  --blue5: #0d6efd;
  --blue6: #0b5ed7;
  --blue7: #1677ff;

  // Green Colors
  --green1: #198754;
  --green2: #157347;

  // Grey Colors
  --grey1: #f0f0f0;
  --grey2: #f7f7ff;
  --grey3: #777777;
  --grey4: #dee2e6;
  --grey5: #f0f0f0;
  --grey6: #e5e5e5;
  --grey7: #6c757d;
  --grey8: #d3d4d5;
  --grey9: #5c636a;
  --grey10: #f8f9fa;

  // Orange Colors
  --orange1: #ffc107;
  --orange2: #e09647;

  // Red Colors
  --red1: #dc3545;
  --red2: #bb2d3b;

  // White & transparent
  --white: #ffffff;
  --transparent: transparent;
}
