.custom_loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #f0f0f0;
    border-radius: 4px;
}

.loader_inner {
    width: 100%;
    height: 100%;
    background-color: #1890ff;
    animation: loading 2s linear infinite;
}

@keyframes loading {
    0% {
        transform: scaleX(0);
    }
    50% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(0);
    }
}
