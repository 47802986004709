.login_card {
    width: 34.4375em;
    margin: 2rem 1rem;
    :global {
        .ant-card-body {
            padding: 2.5rem;
        }
    }
    img {
        width: 160px;
    }
}
