.summary_wrapper {
  .summary_card {
    box-shadow: 0 2px 6px 0 rgba(218, 218, 253, 0.65),
      0 2px 6px 0 rgba(206, 206, 238, 0.54);
    border: none;
    border-left: 4px solid;
    height: 100%;
    .summary_values {
      margin-top: 0.25em;
    }
    .widgets_icons {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 27px;
      border-radius: 50%;
      color: var(--white);
    }

    &.blue {
      border-color: var(--blue4);
      .summary_values {
        color: var(--blue4);
      }
      .widgets_icons {
        background: linear-gradient(
          to right,
          rgb(86, 204, 242),
          rgb(47, 128, 237)
        );
      }
    }
    &.red {
      border-color: var(--red1);
      .summary_values {
        color: var(--red1);
      }
      .widgets_icons {
        background: linear-gradient(
          to right,
          rgb(255, 65, 108),
          rgb(255, 75, 43)
        );
      }
    }
    &.green {
      border-color: var(--green1);
      .summary_values {
        color: var(--green1);
      }
      .widgets_icons {
        background: #00b09b;
        background: -webkit-linear-gradient(45deg, #00b09b, #96c93d);
        background: linear-gradient(45deg, #00b09b, #96c93d);
      }
    }
    &.orange {
      border-color: var(--orange1);
      .summary_values {
        color: var(--orange1);
      }
      .widgets_icons {
        background: #fc4a1a;
        background: -webkit-linear-gradient(45deg, #fc4a1a, #f7b733);
        background: linear-gradient(45deg, #fc4a1a, #f7b733);
      }
    }
  }
}

.product_card {
  .product_doughnut {
    padding: 1rem;
    border-bottom: 1px solid var(--grey4);
  }
  .product_list {
    .product_list_items {
      padding: 0.5rem 1rem;
      border-color: var(--grey4);
      .product_list_items_title {
        color: var(--black4);
      }
      .product_list_items_value {
        border-radius: 3em;
        padding: 0.35em 0.65em;
        color: var(--white);
        font-size: 0.75em;
        font-weight: 700;
        &.blue {
          background-color: var(--blue4);
        }
        &.red {
          background-color: var(--red1);
        }
        &.green {
          background-color: var(--green1);
        }
        &.orange {
          background-color: var(--orange1);
        }
      }
    }
  }
  &.no_data_card {
    :global {
      .ant-card-body {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.consumption_card {
  .tags {
    padding: 1rem;
    span {
      border: 1px solid var(--grey4);
      border-radius: 0.375rem;
      padding: 0 0.25rem;
    }
  }
  .consumption_data {
    padding: 0 0.5rem;
    .consumption_item {
      padding: 1rem;
    }
  }
  .no_data {
    height: 80%;
  }
  :global {
    .ant-card-body {
      height: calc(100% - 60px);
      .ant-spin-nested-loading {
        height: 100%;
        .ant-spin-container {
          height: 100%;
        }
      }
    }
  }
}

.recent_orders_card {
  .recent_orders_wrap {
    .map_wrap {
      border-right: 1px solid var(--grey4);
    }
  }
}

.like_share_comment_card {
  .lsc_card_item {
    border: 1px solid var(--black5);
    border-top: none;

    &:first-child {
      border-top: 1px solid var(--black5);
      border-radius: 0.375rem 0.375rem 0 0;
    }
    &:last-child {
      border-radius: 0 0 0.375rem 0.375rem;
    }
    .widgets_icons {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 27px;
      border-radius: 10px;
      color: var(--white);
      &.purple {
        background: linear-gradient(to right, #8e2de2, #4a00e0);
      }
      &.red {
        background: #ee0979;
        background: -webkit-linear-gradient(45deg, #ee0979, #ff6a00);
        background: linear-gradient(45deg, #ee0979, #ff6a00);
      }
      &.yellow {
        background: linear-gradient(to right, #f7971e, #ffd200);
      }
    }
  }
}

.both_user_dashboard {
  :global {
    .ant-tabs-nav-list {
      gap: 1rem;
      .ant-tabs-tab {
        background-color: white;
        padding: 0.6rem 1rem;
        border-radius: 3px;
        box-shadow: 0 2px 6px 0 rgba(218, 218, 253, 0.65),
          0 2px 6px 0 rgba(206, 206, 238, 0.54);
        min-width: 7rem;
        justify-content: center;
        margin: 0;
      }
    }
  }
}

.graph_card {
  padding: 0.5rem 0.5rem 1rem;
}

.infoText {
  color: var(--grey7);
}
