* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
}
html {
  width: 100vw;
}
#root {
  width: 100vw;
}
body {
  color: #4c5258;
  letter-spacing: 0.5px;
  background-color: hsl(240, 100%, 98%);
  font-size: 14px;
  width: 100vw;
  overflow: hidden auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.2;
  font-weight: 500;
}

.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw);
}

.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw);
}

.h3,
h3 {
  font-size: calc(1.3rem + 0.6vw);
}
.h4,
h4 {
  font-size: calc(1.275rem + 0.3vw);
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}

body,
.ant-btn,
.ant-form-item-label .ant-input,
.ant-tag,
.ant-typography,
.ant-upload {
  font-family: "Rubik", sans-serif !important;
}

body {
  .ant-dropdown {
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        color: var(--black4);
        &:hover {
          background-color: var(--blue3);
        }
      }
    }
  }
}

main {
  padding: 1.5rem 1rem;
}

@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.5rem;
  }
  .h2,
  h2 {
    font-size: 2rem;
  }
  .h3,
  h3 {
    font-size: 1.75rem;
  }
  .h4,
  h4 {
    font-size: 1.5rem;
  }
}

::-webkit-scrollbar {
  height: 0.5em;
  width: 0.5em;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(174 174 196 / 72%);
  border-color: rgb(174 174 196 / 72%);
  border-radius: 1em;
  border-width: 1px;
  opacity: 1;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 9999px;
}
