@for $i from 1 through 8 {
  .gap_#{$i} {
    gap: #{$i * 0.25}rem;
  }
}

.display {
  &_none {
    display: none;
  }
  &_block {
    display: block;
  }
  &_flex {
    display: flex;
  }
  &_centered {
    align-items: center;
    justify-content: center;
  }
  &_center_spacebetween {
    align-items: center;
    justify-content: space-between;
  }
  &_end {
    align-items: flex-end;
    justify-content: flex-end;
  }
  &_start {
    align-items: flex-start;
    justify-content: flex-start;
  }
  &_column {
    flex-direction: column;
  }
  &_a_center {
    align-items: center;
  }
  &_a_end {
    align-items: flex-end;
  }
  &_a_start {
    align-items: flex-start;
  }
  &_j_center {
    justify-content: center;
  }
  &_j_end {
    justify-content: flex-end;
  }
  &_j_start {
    justify-content: flex-start;
  }
  &_j_spacebetween {
    justify-content: space-between;
  }
  &_flex_wrap {
    flex-wrap: wrap;
  }
}
.position {
  &_relative {
    position: relative;
  }
  &_absolute {
    position: absolute;
  }
}
.m {
  &_x_auto {
    margin: 0 auto;
  }
  &_l_auto {
    margin-left: auto;
  }
  &_xy_1 {
    margin: 1rem;
  }
  &_x_1 {
    margin: 0 1rem;
  }
  &_y_1 {
    margin: 1rem 0;
  }
  &_y_0_2_5 {
    margin: 0.25rem 0;
  }
  &_b_0 {
    margin-bottom: 0;
  }
  &_b_0_5 {
    margin-bottom: 0.5rem;
  }
  &_b_1 {
    margin-bottom: 1rem;
  }
  &_b_1_5 {
    margin-bottom: 1.5rem;
  }
  &_b_0_2 {
    margin-bottom: 0.2rem;
  }
  &_t_3 {
    margin-top: 3rem;
  }
  &_t_1_5 {
    margin-top: 1.5rem;
  }
}
.p {
  &_xy_1 {
    padding: 1rem;
  }
  &_x_1 {
    padding: 0 1rem;
  }
  &_y_1 {
    padding: 1rem 0;
  }
}
.gap6 {
  gap: 6px;
}
.gap8 {
  gap: 8px;
}
.gap10 {
  gap: 10px;
}
.gap16 {
  gap: 16px;
}
.gap24 {
  gap: 24px;
}

.text_align_center {
  text-align: center;
}
.width100 {
  width: 100%;
}
.widthAuto {
  width: auto !important;
}
.height100 {
  height: 100%;
}
.pointer {
  cursor: pointer;
}

.globalCheckbox {
  :global {
    .ant-checkbox-wrapper {
      color: var(--grey2);
      font-weight: 500;
      text-transform: capitalize;
      .ant-checkbox {
        .ant-checkbox-inner {
          border-color: var(--grey7);
          width: 20px;
          height: 20px;
          border-radius: 6px;
        }
      }
      .ant-checkbox-checked,
      .ant-checkbox-checked:hover,
      &:hover .ant-checkbox-checked {
        .ant-checkbox-inner {
          border-color: var(--green1) !important;
          background-color: var(--green1) !important;
          &::after {
            height: 12px;
            top: 47%;
            left: 27%;
          }
        }
        &:after {
          border-color: var(--green1) !important;
        }
      }

      .ant-checkbox-indeterminate .ant-checkbox-inner:after {
        background-color: var(--green1);
      }
    }
  }
}

.progressModal {
  :global {
    .ant-modal-content {
      padding: 0;
      height: 100%;
      border-radius: 16px;
      .ant-modal-header {
        border-radius: 16px 16px 0 0;
        padding: 16px 16px 16px 24px;
        border-bottom: 1px solid var(--grey6);
        margin: 0;
        .ant-modal-title {
          font-weight: 500;
          color: var(--grey1);
        }
      }
    }
  }
}

.bg-gradient-blues {
  background: linear-gradient(
    to right,
    rgb(86, 204, 242),
    rgb(47, 128, 237)
  ) !important;
}
.bg-gradient-burning {
  background: linear-gradient(
    to right,
    rgb(255, 65, 108),
    rgb(255, 75, 43)
  ) !important;
}
.bg-gradient-ohhappiness {
  background: #00b09b;
  background: -webkit-linear-gradient(45deg, #00b09b, #96c93d) !important;
  background: linear-gradient(45deg, #00b09b, #96c93d) !important;
}
.bg-gradient-orange {
  background: #fc4a1a;
  background: -webkit-linear-gradient(45deg, #fc4a1a, #f7b733) !important;
  background: linear-gradient(45deg, #fc4a1a, #f7b733) !important;
}

.text_secondary {
  color: var(--grey7);
}

.card_wrapper {
  height: 100%;
  border: 1px solid var(--black5);
  :global {
    .ant-card-head {
      min-height: 0;
      padding: 0.5rem 1rem;
      border-bottom: 1px solid var(--black5);
      background-color: rgba(33, 37, 41, 0.03);
      .ant-card-head-title {
        font-size: 18px;
      }
    }
    .ant-card-body {
      padding: 0;
    }
  }
}

.table_wrap {
  padding: 1em;
  overflow: auto;
  :global {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      border-bottom: 1px solid var(--grey4);
      padding: 0.75rem;
      font-size: 14px;
    }
  }
}

.modal_wrapper {
  :global {
    .ant-modal-content {
      padding: 0;
      .ant-modal-header {
        padding: 1rem;
        border-bottom: 1px solid var(--grey4);
      }
      .ant-modal-body {
        max-height: 80vh;
        overflow: hidden;
        .modal_form {
          max-height: 70vh;
          padding: 0.5rem 1rem 1rem;
          overflow: hidden auto;
        }
        .modal_footer {
          display: flex;
          justify-content: flex-end;
          gap: 8px;
          padding: 1rem;
          border-top: 1px solid var(--grey4);
          position: sticky;
          bottom: 0;
          background: #fff;
          width: 100%;
          border-radius: 0 0px 10px 10px;
        }
      }
      .ant-modal-footer {
        padding: 0.5rem 1rem 1rem;
        border-top: 1px solid var(--grey4);
      }
    }
  }
}

.recent_order_progress {
  :global {
    .ant-progress-bg {
      background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
      );
      background-size: 1rem 1rem;
    }
  }
}

.wideModal {
  width: 75% !important;
  h6 {
    font-size: 14px;
  }
  :global .ant-row {
    padding-bottom: 0.5rem;
  }
}
