.snackbar_wrapper {
    :global {
        .ant-notification-notice-content {
            .ant-notification-notice-message {
                margin-bottom: 0;
                padding: 1rem;
            }
        }
        .ant-notification-notice-close {
            top: 50% !important;
            transform: translateY(-50%) !important;
            inset-inline-end: 1rem !important;
        }
    }
    .snackbar_body {
        svg {
            width: 1.3rem;
            font-size: 1.3rem;
        }
        p {
            width: calc(100% - 3rem);
        }
    }

    &.success {
        background-color: #baf2bc;
        :global {
            .ant-notification-notice-content {
                .ant-notification-notice-message {
                    margin-bottom: 0;
                    padding: 1rem;
                    color: #0d7c14;
                }
            }
            .ant-notification-notice-close {
                color: #0d7c14 !important;
            }
        }
    }

    &.error {
        background-color: #fcd0cf;
        :global {
            .ant-notification-notice-content {
                .ant-notification-notice-message {
                    margin-bottom: 0;
                    padding: 1rem;
                    color: #da100b;
                }
            }
            .ant-notification-notice-close {
                color: #da100b !important;
            }
        }
    }

    &.warning {
        background-color: #fbe5c9;
        :global {
            .ant-notification-notice-content {
                .ant-notification-notice-message {
                    margin-bottom: 0;
                    padding: 1rem;
                    color: #bf710f;
                }
            }
            .ant-notification-notice-close {
                color: #bf710f !important;
            }
        }
    }
}
