.forgot_password_card {
  width: 34.4375em;
  margin: 2rem 1rem;
  :global {
    .ant-card-body {
      padding: 2.5rem;
    }
  }
  img {
    width: 160px;
  }
  .resend_otp {
    color: var(--black1);
    font-size: 13px;
    span {
      color: var(--blue7);
      cursor: pointer;
      margin-left: 2px;
      font-weight: 500;
    }
  }
}
